.map {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
    z-index: 0;
}

.image_container {
    width: 100%;
    height: 100%;
}