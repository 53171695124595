.card {
  width: 20rem;
  height: 12rem;
  position: relative;
  cursor: pointer;
  margin: 1rem;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}

.name {
  color: white;
  position: absolute;
  bottom: 0;
  font-family: "Montserrat", sans-serif;
  padding: 0.5rem;
  max-width: 75%;
  z-index: 5;
}
