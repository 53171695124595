.content {
  padding: 4rem 0 2rem 0;
}

.partition {
  display: flex;
}

.leftSide {
  width: 100%;
  display: flex;
  align-items: center;
}

.imageContainer {
  width: 12rem;
  height: 12rem;
}

.anna {
  width: 100%;
  border-radius: 100%;
}

@media (max-width: 992px) {
  .partition {
    flex-direction: column;
  }
  .imageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    /* align-items: left; */
    /* justify-content: center; */
  }
  .anna {
    width: 60%;
  }
}

.partition > h1 {
  font-size: 2rem;
  font-family: "Libre Baskerville", serif;
  color: #213555;
  font-weight: 400;
  padding: 2rem 0;
}

.contentItem {
  padding: 1rem 0;
}

.contentItem > h2 {
  color: #4f709c;
  font-family: "Montserrat", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
}

.contentItem > p {
  font-family: "Montserrat", sans-serif;
  color: #2f2f2f;
  font-weight: 400;
}

.cardContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.content > ul {
  font-family: "Montserrat", sans-serif;
  list-style-type: disc;
  padding-left: 2rem;
}

.content > ul > li {
  padding: 0.5rem;
}

@media (max-width: 992px) {
  .content > ul {
    padding-left: 1rem;
  }
}
