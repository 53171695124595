.background {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 0;
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
}

.bgContainer {
  width: 100%;
  height: 60vh;
  position: relative;
  margin-top: -1rem;
}

.title {
  position: absolute;
  color: white;
  bottom: 0;
  padding-bottom: 3rem;
  font-size: 2rem;
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 992px) {
  .bgContainer {
    margin-top: 0;
  }

  .title {
    left: 50%;
    transform: translateX(-50%);
  }
}
