.stats {
  margin: 4rem 0;
}

.heading {
  color: #213555;
  text-align: center;
  font-family: Montserrat;
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.09rem;
  margin: 1rem 0;
}

.subheading {
  color: #2f2f2f;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  width: 80%;
  margin: 0 auto 1rem auto;
}

.card_container {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  padding: 2rem 0;
}

.card {
  width: 20%;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card_number {
  color: #4f709c;
  text-align: center;
  font-family: Libre Baskerville;
  font-size: 5rem;
  font-style: normal;
  font-weight: 400;
}

.card_title {
  color: #4f709c;
  text-align: center;
  font-family: Libre Baskerville;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
}

.card_subtitle {
  color: #213555;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 300;
}

.card > p {
  color: #2f2f2f;
  text-align: center;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  margin: 2rem 0;
}

.leafs {
  width: 12%;
}

@media (max-width: 992px) {
  .stats {
    margin: 2rem 0;
  }

  .heading {
    font-size: 2rem;
  }

  .subheading {
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  .card_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem;
  }

  .card {
    width: 60%;
    padding: 1rem;
  }

  .card_number {
    font-size: 3rem;
  }

  .card_title {
    font-size: 1.5rem;
  }

  .card_subtitle {
    font-size: 1rem;
  }

  .card > p {
    font-size: 0.8rem;
  }

  .leafs {
    width: 25%;
    margin: 1rem 0;
  }
}
