.footer {
  margin: 3rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn_container {
  margin: 1.5rem 0;
}

.phone,
.address {
  color: #2f2f2f;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  padding-bottom: 1rem;
}

.address {
  margin-bottom: 1.5rem;
}

.copyright {
  color: #2f2f2f;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  padding: 1rem 0;
}

.logo {
  width: 12rem;
}

@media (max-width: 992px) {
  .logo {
    width: 50%;
  }

  .phone,
  .address {
    font-size: 1rem;
  }

  .copyright {
    font-size: 0.8rem;
  }
}
