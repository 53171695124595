.herosection {
  min-width: 100%;
  min-height: 100%;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  z-index: 0;
}

.content {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
}

.heading_image {
  position: relative;
  z-index: 1;
  width: 70%;
  margin-bottom: 3rem;
}

.button_container {
  display: flex;
  flex-direction: row;
}

.button_container > span:first-child {
  margin-right: 0.5rem;
}

.button_container > span:last-child {
  margin-left: 0.5rem;
}

@media (max-width: 992px) {
  .heading_image {
    width: 100%;
  }

  .button_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .button_container > span:first-child {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .button_container > span:last-child {
    margin-left: 0;
    margin-top: 0.5rem;
  }
}

.more_info {
  width: 90%;
  margin: -5rem auto 1rem auto;
  padding: 2rem 0;
  border-radius: 0.5rem;
  background-color: #dadfe8;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  z-index: 5;
  position: relative;
}

.more_info_card {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-right: 1px solid #bbcac3;
  margin-right: -1px;
}

.more_info_card:last-child {
  border-right: none;
}

.more_info_image {
  aspect-ratio: 1/1;
  width: 45%;
}

.more_info_card > h1 {
  color: #213555;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.5rem;
  font-weight: 400;
  padding: 1rem 0;
}

.more_info_card > p {
  color: #2f2f2f;
  text-align: center;
  width: 90%;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  padding: 0 0 1rem 0;
}

.more_info_card > a {
  color: #4f709c;
  text-align: center;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  text-decoration-line: underline;
}

.more_info_card > a:hover {
  text-decoration-line: none;
}

@media (max-width: 992px) {
  .more_info_card {
    width: 50%;
    padding: 1rem 0rem;
  }

  .more_info_card:nth-child(1) {
    border-bottom: 1px solid #bbcac3;
    margin-bottom: -1px;
  }

  .more_info_card:nth-child(2) {
    border-right: none;
    border-bottom: 1px solid #bbcac3;
    margin-bottom: -1px;
  }

  .more_info_image {
    aspect-ratio: 1/1;
    width: 70%;
  }

  .more_info_card > h1 {
    color: #213555;
    text-align: center;
    font-family: Montserrat;
    font-size: 1.2rem;
    font-weight: 400;
    padding: 0.8rem 0;
  }

  .more_info_card > p {
    color: #2f2f2f;
    text-align: center;
    font-family: Montserrat;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    padding: 0 0 0.8rem 0;
  }

  .more_info_card > a {
    color: #4f709c;
    text-align: center;
    font-family: Montserrat;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    text-decoration-line: underline;
  }
}
