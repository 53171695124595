.header {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 1rem 0;
}

.image_container {
  width: 6rem;
}

.links {
  color: #efefefed;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 1.125rem;
  text-decoration: none;
}

.links:hover {
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.link_container {
  display: flex;
  align-items: center;
}

.link_container > li {
  margin: 0 0.75rem;
}

@media (max-width: 992px) {
  .image_container {
    width: 4rem !important;
  }

  .header {
    margin-top: 0;
    padding-top: 0.5rem;
  }

  .links {
    font-size: 1rem;
  }

  .link_container {
    display: none;
  }

  .image_container {
    width: 12rem;
    height: auto;
  }
}

/* Mobile Navigation */

.hamburger {
  display: none;
}

.hamburgerBun,
.hamburgerLoaf,
.hamburgerBase {
  width: 2rem;
  height: 0.25rem;
  border-radius: 0.5rem;
  margin: 2.5px 0;
  background-color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.activeHamburgerBun {
  width: 2rem;
  height: 0.25rem;
  border-radius: 0.5rem;
  margin: 2.5px 0;
  background-color: #ffffff;
  transform: rotate(-45deg) translateY(0.4rem) translateX(-0.4rem);
  transition: all 0.3s ease-in-out;
}

.activeHamburgerLoaf {
  width: 2rem;
  height: 0.25rem;
  border-radius: 0.5rem;
  margin: 2.5px 0;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
}

.activeHamburgerBase {
  width: 2rem;
  height: 0.25rem;
  border-radius: 0.5rem;
  margin: 2.5px 0;
  background-color: #ffffff;
  transform: rotate(45deg) translateY(-0.4rem) translateX(-0.4rem);
  transition: all 0.3s ease-in-out;
}

.mobileNavigation {
  width: 100vw;
  height: 0;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 10;
  background-color: #213555;
  transition: all 0.3s ease-in-out;
}

.activeMobileNavigation {
  width: 100vw;
  height: auto;
  padding: 2rem 0;
  margin: 0;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 10;
  background-color: #213555;
  transition: all 0.3s ease-in-out;
}

.hiddenMobileNavigationLinks {
  display: none;
}

.activeMobileNavigationLinks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.activeMobileNavigationLinks > li {
  padding: 0.5rem 0;
}

@media (max-width: 992px) {
  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 15;
    width: 3rem;
    height: 3rem;
    margin-right: 1.5rem;
  }
}
