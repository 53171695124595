.intro {
  font-family: "Montserrat", sans-serif;
  color: #2f2f2f;
  padding: 6rem 0 4rem 0;
  width: 75%;
  margin: 0 auto;
  text-align: center;
}

/*.form {*/
/*  padding-top: 6rem;*/
/*}*/

.inputContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.inputContainer > input {
  width: 50%;
  height: 4rem;
  background-color: #dadfe8;
  border-radius: 0.3rem;
  padding: 0 1rem;
  color: #213555;
  border: none;
  margin: 1rem;
  font-family: "Montserrat", sans-serif;
}

.inputContainer > input:active,
.inputContainer > input:focus {
  outline: 2px solid #213555;
}

.inputContainer > textarea {
  width: 100%;
  background-color: #dadfe8;
  border-radius: 0.3rem;
  padding: 1rem;
  color: #213555;
  border: none;
  margin: 1rem;
  font-family: "Montserrat", sans-serif;
}

.inputContainer > textarea:active,
.inputContainer > textarea:focus {
  outline: 2px solid #213555;
}

.buttonGroup {
  padding: 4rem 0 2rem 0;
  display: flex;
  justify-content: center;
}

.buttonGroup > button:first-child {
  margin-right: 0.5rem;
}

.buttonGroup > button:last-child {
  margin-left: 0.5rem;
}

@media (max-width: 992px) {
  .intro {
    width: 100%;
  }

  .inputContainer {
    flex-direction: column;
  }

  .inputContainer > input,
  .inputContainer > textarea {
    width: 80%;
  }
}
